import { Article } from "@tm/models"
import { TraderArticle } from "../business/helpers"
import { OfferItemCatalogPart } from "./OfferItemCatalogPart"
import { OfferItemWholesalerPart } from "./OfferItemWholesalerPart"
import { OfferItemWholesalerPartWide } from "./OfferItemWholesalerPartWide"
import { OfferItemCatalogPartWide } from "./OfferItemCatalogPartWide"

type Props = {
    workTaskId: string
    part: Article | TraderArticle
    advertisementCategoryId: string
    variant?: "onlyContent" | "widget"
    onRequestPartList(): void
}

export function OfferItem(props: Props) {
    const { part, variant } = props

    if ("isTraderArticle" in part) {
        return variant === "onlyContent" ? <OfferItemWholesalerPartWide {...props} part={part} /> : <OfferItemWholesalerPart {...props} part={part} />
    }

    return variant === "onlyContent" ? <OfferItemCatalogPartWide {...props} part={part} /> : <OfferItemCatalogPart {...props} part={part} />
}
