export function shouldUseNewModal(source?: string) {
    switch (source) {
        case "TM_MVC_HP":
        case "TM_MVC_TECRMI":
        case "TM_MVC_AD":
        case "TM_MVC_HP_INSP":
        case "TM_MVC_HP_SERVICE_INTERVALS":
        case "TM_MVC_DAT":
        case "TM_MVC_TMTRUCK":
        case "TM_MVC_TRUCK_GARAGE":
        case "TM_MVC_HP_PARTS_REQUIRED":
        case "TM_MVC_HP_REPAIR_TIMES":
        case "TM_MVC_HP_NETWORK_SIGNAL":
        case "TM_MVC_HP_RM_DIRECT_SEARCH":
        case "TM_MVC_HP_RECALLS":
        case "TM_MVC_AD_SERVICE_INTERVALS":
            return true

        default:
            return false
    }
}
