import { Article, OE, OrderItem } from "@tm/models"

export function mapOrderItemToArticle(item: OrderItem): Partial<Article> {
    return {
        id: item.id,
        internalId: -1,
        quantity: item.quantity.value,
        description: item.articleInfo.description,
        supplier: {
            id: item.articleInfo.dataSupplier?.id || 0,
            name: item.articleInfo.dataSupplier?.name || item.articleInfo.dataSupplierArticleNumber || "",
            abbreviation: "",
            isTopPriority: false,
        },
        supplierArticleNo: item.articleInfo.dataSupplierArticleNumber,
        traderArticleNo: item.articleInfo.wholesalerArticleNumber,
        productGroup: {
            id: item.articleInfo.productGroup?.id || -1,
            name: item.articleInfo.productGroup?.name || "",
            sortId: -1,
            thumbnail: item.articleInfo.thumbnailUrl || "",
        },
        additionalDescription: "",
        initialQuantity: item.quantity.value,
        requestErpInfo: true,
        showAddToBasket: false,
        attributes: [],
        references: [],
        information: [],
    }
}

export function mapOrderItemToOePart(orderItem: OrderItem): OE.OePart {
    return {
        number: orderItem.articleInfo.oeArticleNumber || "",
        description: orderItem.articleInfo.description,
        manufacturerId: orderItem.articleInfo.vehicleManufacturer?.id,
        manufacturerName: orderItem.articleInfo.vehicleManufacturer?.name,
        prices: [
            {
                value: orderItem.articleInfo.oePrice?.amount || 0,
                currency: orderItem.articleInfo.oePrice?.currencyCode || orderItem.articleInfo.oePrice?.currencySymbol || "",
            },
        ],
        oeArticleOrigin: orderItem.articleInfo.origin,
        quantity: orderItem.quantity.value,
    }
}
