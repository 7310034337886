import { useCallback, useRef } from "react"
import { useCisCustomer, useTelesalesCustomerNumber, useWorkTask } from "@tm/context-distribution"
import { Widget, Button, Toolbar, WidgetSizes, Dialog, Loader } from "@tm/controls"
import { style } from "typestyle"
import { Typography } from "@tm/components"
import { getLocalization } from "@tm/localization"
import {
    useMultiWidgetState,
    renderRoute,
    RouteComponentProps,
    withRouter,
    encodeUniqueId,
    useExternalCatalogUrl,
    ModulePropsMultiWidget,
    createQueryString,
    useHeighAdjustment,
} from "@tm/utils"
import { useHistory } from "react-router"
import { channel } from "@tm/models"
import { getComponentStyles } from "./styles"

type Props = RouteComponentProps & {
    showComponentKey?: string
    detailsRoute: string
    height?: number
    size?: WidgetSizes
    externalSystemId?: number
    moduleProps?: ModulePropsMultiWidget
    openDetailsVariant?: "fullScreen" | "workTask"
    heightObservationName?: string
}

function PartnerWidget({
    showComponentKey,
    detailsRoute,
    height,
    size,
    externalSystemId,
    moduleProps,
    match,
    openDetailsVariant,
    heightObservationName,
}: Props) {
    const history = useHistory()
    const { translateText } = getLocalization()
    const [selectedMultiWidgetTab] = useMultiWidgetState(moduleProps)
    const className = style(getComponentStyles())
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { workTaskId, workTask } = useWorkTask() || {}
    const customer = workTask?.customer
    const newSize = size && /\d+x\d+/g.test(size) ? size : "4x2"
    const { externalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId,
        telesalesCustomerNo,
    })
    const externalDialogRef = useRef<Dialog>(null)
    const { cisCustomer: partner, cisCustomerLoading: partnerLoading } = useCisCustomer()
    const { createWorkTask } = useWorkTask() ?? {}

    const handleRef = useCallback((ref: HTMLDivElement | null) => {
        if (ref && heightObservationName) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useHeighAdjustment().allowHeightObservation(ref, heightObservationName)
        }
    }, [])

    const handleOpenExternalUrl = useCallback(() => {
        if (openDetailsVariant == "fullScreen" && workTaskId) {
            if (externalCatalogUrl) {
                history.push(`/${encodeUniqueId(workTaskId)}/ext?url=${encodeURIComponent(externalCatalogUrl)}`)
            }
            return
        }
        externalDialogRef.current?.show()
    }, [openDetailsVariant, workTaskId, history, externalCatalogUrl])

    const handleOpenTelesalesHistory = () => {
        if (workTaskId && partner) {
            const workTaskHistoryRoute = `/${encodeUniqueId(workTaskId)}/telesales-history`
            const url =
                renderRoute(workTaskHistoryRoute, {
                    ...match.params,
                }) + createQueryString({ query: encodeURIComponent(partner.customerNo) })
            history.push(url)

            channel("WORKTASK", workTaskId).publish("MODULE/OPENED", {
                key: "telesales-history",
                title: "{{13587}}",
                icon: "menu",
                url,
                isSelected: true,
            })
        }
    }

    function renderContent() {
        if (!customer) {
            return <Typography variant="body1">{translateText(13133)}</Typography>
        }
        let customerName = `${customer.academicTitle || ""} ${customer.firstName || ""} ${customer.lastName || ""}`.trim()
        if (!customerName) {
            customerName = "-"
        }
        return (
            <div className={`${className}__content`}>
                <div className={`${className}__head`}>
                    <div className="subtitle subtitle--xs">{translateText(1768)}</div>
                    <span className="no headline--xs">{customer.displayCustomerNo || customer.refCustomerNo}</span>
                    <span className="name text--strong headline--xs">{customer.companyName}</span>
                    <span className="addInfo headline--xs">
                        {partnerLoading ? <Loader className={`${className}__small-Loader`} /> : partner?.companyNameAddition1}
                    </span>
                </div>
                <div className={`${className}__body`}>
                    <div className={`${className}__details`}>
                        <Toolbar title={translateText(111)}>{customer.street || "-"}</Toolbar>
                        <Toolbar title={`${translateText(112)}, ${translateText(113)}`}>
                            {[customer.zip, customer.city].filter((x) => !!x).join(" ") || "-"}
                        </Toolbar>
                    </div>
                    <div className={`${className}__details`}>
                        <Toolbar title={translateText(110)}>{customer.phone || "-"}</Toolbar>
                        <Toolbar title={translateText(950)}>{customerName}</Toolbar>
                    </div>
                </div>
            </div>
        )
    }

    function renderFooter() {
        if (!customer) {
            return
        }
        const url = renderRoute(detailsRoute, {
            ...match.params,
            customerId: encodeUniqueId(customer.id),
        })

        return (
            <div className={`${className}__footer`}>
                <Button linkTo={externalCatalogUrl ? undefined : url} onClick={externalCatalogUrl ? handleOpenExternalUrl : undefined}>
                    {translateText(116)}
                </Button>
                {!!externalCatalogUrl && (
                    <Dialog className="form-confirmation" ref={externalDialogRef} text={translateText(1084)} iconName="users" layout="stretch">
                        <iframe className="PDF-IFrame" src={externalCatalogUrl} />
                    </Dialog>
                )}

                <Button onClick={() => createWorkTask?.({ customer })} disabled={!createWorkTask}>
                    {translateText(12927)}
                </Button>

                <Button onClick={handleOpenTelesalesHistory}>{translateText(13587)}</Button>
            </div>
        )
    }

    const id = "telesales__partner-widget"

    if (showComponentKey) {
        if (selectedMultiWidgetTab !== showComponentKey) {
            return null
        }

        return (
            <div id={id} className={className} style={{ height: `${height}em` }}>
                <div className="widget__inner">
                    <div className="widget__content">{renderContent()}</div>
                    <div className="widget__footer">{renderFooter()}</div>
                </div>
            </div>
        )
    }

    return (
        <Widget
            height={height}
            size={newSize}
            forwardedRef={handleRef}
            iconName="user"
            title={translateText(839)}
            className={className}
            id={id}
            footer={renderFooter()}
            active
        >
            {renderContent()}
        </Widget>
    )
}

export default withRouter(PartnerWidget)
