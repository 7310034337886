import { Grid, Stack } from "@tm/components"
import { useEffect, useMemo, useRef, useState } from "react"
import { Route } from "react-router"
import { ArticleErpInfo } from "@tm/models"
import { SearchTreeRefType } from "../../searchtreeV2/component"
import { ArticleListWrapper, ArticleListWrapperRefType } from "../components/ArticleListWrapper"
import { ModuleHeader } from "../components/ModuleHeader"
import { SearchTree } from "../components/Searchtree"
import { UniversalPartsList } from "./UniversalPartsList"
import { ModalModuleHeader } from "../components/ModuleHeader/ModalModuleHeader"
import { UniversalPartsListParamsProvider, useUniversalPartsListParamsContext } from "./ContextProvider/ListParams"
import { UniversalPartsFiltersProvider } from "./ContextProvider/Filters"
import { UniversalPartsArticlesProvider } from "./ContextProvider/Articles"
import { SupplierLogosProvider } from "../ContextProvider/SupplierLogos"
import { TradeReferenceProvider } from "../ContextProvider/TradeReferences"
import { ErpInfosProvider } from "../ContextProvider/ErpInfos"
import { BasketQuantitiesProvider } from "../ContextProvider/BasketQuantities"
import { ActiveVehicleDataProvidersProvider } from "../ContextProvider/ActiveVehicleDataProviders"
import { ProductGroupRepairTimesProvider } from "../ContextProvider/ProductGroupRepairTimes"
import { NotesProvider } from "../ContextProvider/Notes"
import { PreviouslyOrderedArticlesProvider } from "../ContextProvider/PreviouslyOrderedArticles"
import { ListOptionsProvider } from "../ContextProvider/ListOptions"
import { ArticleListActionsProvider } from "../ContextProvider/ArticleListActions"
import { ComposerComponent, ContextComposer } from "../ContextProvider"
import { WatchListProvider } from "../ContextProvider/WatchList"
import { SearchType } from "../../../business"
import { PartToReplace } from "../components/PartToReplace"
import { usePartsAlternativesModuleState } from "../PartsAlternatives/PartsAlternativesModuleState"

function UniversalPartsComponent() {
    const { startParams, showAvailable, productGroups, suppliers, attributes } = useUniversalPartsListParamsContext()
    const hasPartToReplace = usePartsAlternativesModuleState((x) => x?.partToReplaceId)
    const isAlternativeSearch = usePartsAlternativesModuleState((x) => !!x)

    const searchTreeRef = useRef<SearchTreeRefType>(null)
    const articleListWrapperRef = useRef<ArticleListWrapperRefType>(null)

    const hasMissingParams = useMemo(() => {
        switch (startParams.type) {
            case "uninode":
                return !startParams.treeId || !startParams.nodeId
            case "uniproductgroups":
                return !startParams.productGroupIds
            case "unisearch":
                return !startParams.query
            default:
                return true
        }
    }, [startParams])

    useEffect(
        function showSearchTreeWhenSearchCannotStart() {
            if (hasMissingParams) {
                searchTreeRef.current?.open()
            }
        },
        [hasMissingParams]
    )

    useEffect(
        function scrollToTopOnListChanged() {
            articleListWrapperRef.current?.scrollToTop()
        },
        [startParams, showAvailable, productGroups, suppliers, attributes]
    )

    return (
        <Stack overflow="hidden" flex={1}>
            <Route path="/:workTaskId/parts/:section/:view/:searchType?" component={ModuleHeader} />
            {/* ModalModuleHeader is only shown for this specific path */}
            <Route path="/parts/alternatives" component={ModalModuleHeader} />
            <Grid display="grid" gridTemplateRows="auto 1fr" overflow="hidden" pr={1} margin={1} flex={1}>
                {!isAlternativeSearch && <SearchTree treeType="universalParts" ref={searchTreeRef} />}
                {!hasMissingParams && (
                    <ArticleListWrapper ref={articleListWrapperRef}>
                        {hasPartToReplace && <PartToReplace searchType={SearchType.UNISEARCH} />}
                        <UniversalPartsList />
                    </ArticleListWrapper>
                )}
            </Grid>
        </Stack>
    )
}

export function UniversalParts() {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [UniversalPartsListParamsProvider],
        [UniversalPartsFiltersProvider],
        [UniversalPartsArticlesProvider, { setHasRendered: setHasListRendered, erpInfos }],
        [SupplierLogosProvider, { isEnabled: hasListRendered }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered }],
        [WatchListProvider, { isEnabled: hasListRendered }],
        [PreviouslyOrderedArticlesProvider, { isEnabled: hasListRendered }],
        [ListOptionsProvider],
        [ArticleListActionsProvider],
    ]
    return (
        <ContextComposer components={components}>
            <UniversalPartsComponent />
        </ContextComposer>
    )
}
