import { Article, ArticleInfoType, OE, OrderItem, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"
import { getArticlesByArticleNoWithOptVehicle } from "../../../../data/repositories"
import { mapOrderItemToArticle, mapOrderItemToOePart } from "../helpers"

export const useGetPartToReplaceData = (partToReplaceId?: string) => {
    const { data, isLoading } = useQuery({
        queryKey: ["partsAlternatives_getPartToReplaceData", partToReplaceId],
        queryFn: async () => {
            const orderItem = await Container.getInstance<OrderItem>(RegisteredModels.Basket_Order_ShowItem)
                .subscribe(partToReplaceId)
                .load()
                .catch(() => undefined)

            if (!orderItem) {
                return { article: undefined, articleInfoType: ArticleInfoType.Undefined }
            }

            const { dataSupplier, dataSupplierArticleNumber, productGroup } = orderItem.articleInfo

            if (orderItem.articleInfoType === ArticleInfoType.OeArticle) {
                const oeArticle = mapOrderItemToOePart(orderItem) as OE.OePart
                return { orderItem, oeArticle, articleInfoType: orderItem.articleInfoType }
            }

            if (orderItem.articleInfoType === ArticleInfoType.TecdocArticle && dataSupplier && dataSupplierArticleNumber && productGroup) {
                try {
                    const articles = await getArticlesByArticleNoWithOptVehicle([
                        {
                            supplierId: dataSupplier.id,
                            supplierArticleNo: dataSupplierArticleNumber,
                            productGroupId: productGroup.id,
                        },
                    ])

                    if (articles?.length) {
                        return {
                            orderItem,
                            article: {
                                ...articles[0],
                                quantity: orderItem.quantity.value || articles[0].quantity,
                            },
                            articleInfoType: orderItem.articleInfoType,
                        }
                    }
                } catch {}
            }

            const article = mapOrderItemToArticle(orderItem) as Article
            return { orderItem, article, articleInfoType: orderItem.articleInfoType }
        },
        enabled: !!partToReplaceId,
        refetchOnMount: true,
        staleTime: 30 * 60 * 1000, // 30 minutes
    })

    return { partToReplaceData: data, isLoaded: !isLoading && !!data?.articleInfoType }
}
