import { Widget, WidgetSizes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { LinkButton, Stack } from "@tm/components"
import { useHeighAdjustment } from "@tm/utils"
import { useCallback } from "react"
import PartnerSearch from "../shared/partner-search"

type Props = {
    detailsRoute: string
    height?: number
    size?: WidgetSizes
    heightObservationName?: string
}

export default function PartnerSearchWidget(props: Props) {
    const { height, size, heightObservationName } = props
    const newSize = size && /\d+x\d+/g.test(size) ? size : "4x2"
    const { translate } = useLocalization()

    const handleRef = useCallback((ref: HTMLDivElement | null) => {
        if (ref && heightObservationName) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useHeighAdjustment().allowHeightObservation(ref, heightObservationName)
        }
    }, [])

    return (
        <Widget
            id="telesales__partner-search-widget"
            height={height}
            size={newSize}
            iconName="user"
            title={translate(839)}
            active
            forwardedRef={handleRef}
        >
            <Stack justifyContent="space-between" flex={1}>
                <PartnerSearch hideTitle />
                <LinkButton to={props.detailsRoute}>{translate(336)}</LinkButton>
            </Stack>
        </Widget>
    )
}
