import { Box, BoxProps, Icon, Stack, Typography } from "@tm/components"
import { getModuleFromUserContext, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ArticleErpInfo, ErpBundleParams, PureListProps, UserModuleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { useCallback, useMemo, useState } from "react"
import { useLocation } from "react-router"
import { PartsListImageCoordinate } from "../../data/model"
import { ArticleGroupHeader } from "../list/components/ArticleGroupHeader/ArticleGroupHeader"
import SensitiveGraphics from "../list/components/sensitive-graphic"
import { ArticleListConfigurationProvider, useArticleFeedbackConfiguration } from "../ListV2/ArticleListConfiguration"
import { ComposerComponent, ContextComposer } from "../ListV2/ContextProvider"
import { ActiveVehicleDataProvidersProvider } from "../ListV2/ContextProvider/ActiveVehicleDataProviders"
import { ArticleListActionsProvider } from "../ListV2/ContextProvider/ArticleListActions"
import { BasketQuantitiesProvider } from "../ListV2/ContextProvider/BasketQuantities"
import { ErpInfosProvider } from "../ListV2/ContextProvider/ErpInfos"
import { EmptyFiltersProvider } from "../ListV2/ContextProvider/Filters"
import { ListOptionsProvider } from "../ListV2/ContextProvider/ListOptions"
import { EmptyListParamsProvider } from "../ListV2/ContextProvider/ListParams"
import { NotesProvider } from "../ListV2/ContextProvider/Notes"
import { PreviouslyOrderedArticlesProvider } from "../ListV2/ContextProvider/PreviouslyOrderedArticles"
import { ProductGroupRepairTimesProvider } from "../ListV2/ContextProvider/ProductGroupRepairTimes"
import { SupplierLogosProvider } from "../ListV2/ContextProvider/SupplierLogos"
import { TradeReferenceProvider } from "../ListV2/ContextProvider/TradeReferences"
import { WatchListProvider } from "../ListV2/ContextProvider/WatchList"
import { useArticleSelection } from "../ListV2/hooks/useArticleSelection"
import { ArticleGroupParams, WholesalerArticleGroup } from "../ListV2/models"
import { usePureListArticlesContext } from "./ContextProviders"
import { PureListArticlesProvider } from "./ContextProviders/Articles"
import { PureArticleItem } from "./PureArticleItem"
import { PureArticleSelection } from "./PureArticleSelection"
import { WholesalerArticleItem } from "./WholesalerArticleItem"

function PureListComponent({ disableArticleGrouping }: PureListProps) {
    const { translateText } = useLocalization()
    const { pathname } = useLocation()
    const { userContext } = useUser()
    const { toggleSelectedArticle, selectedArticles } = useArticleSelection()
    const { articleGroups, articles, response } = usePureListArticlesContext()
    const showNotes = useMemo<boolean>(() => !!getModuleFromUserContext(userContext, UserModuleType.ArticleNotes), [userContext.modules])
    const feedbackConfiguration = useArticleFeedbackConfiguration()
    const { hideExtendedDeliveryComponent } = Morpheus.getParams<ErpBundleParams>("erp")

    // TODO: calc() is extremely dirty here. as soon as we build a new details modal, we can build this another way
    // Added also the width.. its not perfect but it works for now, also there we need a better soulution.
    // Changed the width... still dirty and for lack of a better modal (NEXT-28548, NEXT-29382)
    const listProps: BoxProps = pathname.includes("/modal") ? { height: "calc(100vh - 150px)", overflow: "auto", width: "calc(85vw - 50px)" } : {}

    const handleSelectImageCoordinate = useCallback(
        (coordinate: PartsListImageCoordinate) => {
            if (!articles) {
                return
            }
            const articlesWithCoordinate = articles.filter(
                (part) => part.internalId === coordinate.referencedArticle && part.imageCoordinates?.includes(`,${coordinate.id};`)
            )
            articlesWithCoordinate.forEach(toggleSelectedArticle)
        },
        [articles]
    )

    const renderArticles = () => {
        if (disableArticleGrouping) {
            return (
                <Stack padding={0.5}>
                    {articles?.map((article) => (
                        <PureArticleItem key={article.internalId} article={article} />
                    ))}
                </Stack>
            )
        }

        return (
            <>
                {articleGroups.map((group) => {
                    if ((group as ArticleGroupParams).productGroup) {
                        const { productGroup, articles } = group as ArticleGroupParams
                        return (
                            <Stack key={productGroup.id}>
                                <ArticleGroupHeader title={productGroup.name} productGroupId={productGroup.id} groupArticlesCount={articles.length} />
                                <Stack padding={0.5}>
                                    {articles.map((article) => (
                                        <PureArticleItem key={article.internalId} article={article} />
                                    ))}
                                </Stack>
                            </Stack>
                        )
                    }
                    const { articles } = group as WholesalerArticleGroup
                    return (
                        <Stack padding={0.5} key="WholesalerArticles">
                            {articles.map((wholesalerArticle) => (
                                <WholesalerArticleItem key={wholesalerArticle.itemId} article={wholesalerArticle} />
                            ))}
                        </Stack>
                    )
                })}
            </>
        )
    }

    return (
        <Stack flex={1} position="relative">
            {response?.type === "alternative" && (
                <Stack direction="row" gap={0.5} padding={1}>
                    <Icon name="info" size="14px" />
                    <Typography variant="label">{translateText(articles?.length ? 12893 : 13881)}</Typography>
                </Stack>
            )}
            {response?.type === "partslist" && response.partsListImage && (
                <Box p={0.5}>
                    <SensitiveGraphics
                        image={response.partsListImage}
                        imageCoordinates={response.imageCoordinates}
                        selectedArticles={selectedArticles}
                        onArticleSelect={handleSelectImageCoordinate}
                    />
                </Box>
            )}
            <>
                <PureArticleSelection />
                <Box {...listProps}>{renderArticles()}</Box>
            </>
        </Stack>
    )
}

export function PureList(props: PureListProps) {
    const [hasListRendered, setHasListRendered] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([]) // provider can hold a global state, where useErpInfos would create a context dependent state

    const components: ComposerComponent[] = [
        [ArticleListConfigurationProvider],
        [EmptyListParamsProvider],
        [EmptyFiltersProvider],
        [PureListArticlesProvider, { setHasRendered: setHasListRendered, erpInfos, ...props }],
        [SupplierLogosProvider, { isEnabled: hasListRendered }],
        [TradeReferenceProvider, { isEnabled: hasListRendered }],
        [ErpInfosProvider, { isEnabled: hasListRendered, erpInfos, setErpInfos }],
        [BasketQuantitiesProvider, { isEnabled: hasListRendered }],
        [ActiveVehicleDataProvidersProvider],
        [ProductGroupRepairTimesProvider, { isEnabled: hasListRendered }],
        [NotesProvider, { isEnabled: hasListRendered, isVehicleDepending: false }],
        [WatchListProvider, { isEnabled: hasListRendered }],
        [PreviouslyOrderedArticlesProvider, { isEnabled: hasListRendered }],
        [ListOptionsProvider, { isEnabled: true }],
        [ArticleListActionsProvider],
    ]
    return (
        <ContextComposer components={components}>
            <PureListComponent {...props} />
        </ContextComposer>
    )
}
