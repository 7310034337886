import { ajaxAuth } from "@tm/utils"
import { getBasketServiceUrl } from "../.."
import { ExportToExternalBasketResponse, ImportExternalBasketResponse } from "../../model/ThirdPartyBasket"

function getServiceUrl() {
    return `${getBasketServiceUrl()}/ThirdPartyBasket`
}

export function importFromExternalBasket(workTaskId: string) {
    const url = `${getServiceUrl()}/ImportExternalBasket`
    const body = { workTaskId }

    return ajaxAuth<ImportExternalBasketResponse>({ url, body, method: "POST" })
}

export function exportToExternalBasket(workTaskId: string) {
    const url = `${getServiceUrl()}/ExportToExternalBasket`
    const body = { workTaskId }

    return ajaxAuth<ExportToExternalBasketResponse>({ url, body, method: "POST" })
}
