import { Component, ReactNode } from "react"
import { BonusPoints, Box, Button, Divider, Icon, Stack, Tooltip, Typography } from "@tm/components"
import { AmountField, AmountItem, Badge, BonusSystemImage, Headline, Image, Loader, PanelSection, Size, SubTitle, Text } from "@tm/controls"
import {
    ActiveVehicleDataProviders,
    Article,
    ChangeItemsResponse,
    EArticleModificationState,
    IArticleDetailsHeadTemplate,
    RegisteredModels,
    RepairTimeProvider,
    SystemType,
    TmaEModule,
    VehicleRecordsContainer,
    ViewState,
    channel,
} from "@tm/models"
import Morpheus, { ComponentIdentifier, TemplateRenderer, importMicro } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import {
    DetailOrderOptionState,
    TmaHelper,
    bindSpecialReactMethods,
    encodeUniqueId,
    getArticleBonusSystemsWithPoints,
    getArticleBonusSystemsWithoutPoints,
    getCategoryTypeFromUrl,
    getRepairTimeProviders,
    getRepairTimesProvider,
    getRepairTimesProviderStringByEnum,
    isCentralOrder,
    parseQueryString,
    renderRoute,
} from "@tm/utils"
import { bundleChannel } from "../../bundle-channel"
import { Models, Repositories } from "../../data"
import { mapVehicleRecordArticleAddedToBasketEventRequest } from "../../data/mapper/vehicle-records"
import { printArticleDetails } from "../../data/repositories"
import { getBundleParams } from "../../utils"
import ArticleNumbers from "../_shared/ArticleNumbers"
import ArticleImageComponent from "../_shared/article-image"
import { NoteActionButton } from "../_shared/article-item/note/note-action-button"
import { createPrintArticleDetailsRequest } from "../_shared/helper"
import { DetailsHeadProps } from "./wrapper"
import AddToCompilations from "./components/AddToCompilations"
import ErpInformation from "./components/ErpInformation"

type State = {
    currentImage: number
    isPrintLoading: boolean
}

type Props = DetailsHeadProps & {
    updateArticle: (article?: Article) => void
    selectedOrderOption: DetailOrderOptionState | undefined
    onFinished: (data: ChangeItemsResponse) => void
    hasCostestimation?: boolean
    showBasketButton?: boolean
}

@importMicro
export class DetailsHeadComponent extends Component<Props, State> implements IArticleDetailsHeadTemplate, TemplateRenderer {
    rendererIdentifier: ComponentIdentifier = {
        bundle: "parts",
        component: "details-head",
    }

    unregisterViewStateLoaded?: () => void

    unregisterViewStateUpdated?: () => void

    unsubscribeArticleQuantityChange?: () => void

    ViewState_ActiveProviders = Container.getInstance<ViewState<ActiveVehicleDataProviders | undefined>>(RegisteredModels.ViewState)

    currentProviders?: ActiveVehicleDataProviders

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            currentImage: 0,
            isPrintLoading: false,
        }

        this.getRepairTimesUrl = this.getRepairTimesUrl.bind(this)
    }

    componentWillUnmount() {
        this.props.updateArticle(undefined)
        this.unregisterViewStateLoaded?.()
        this.unregisterViewStateUpdated?.()
        this.unsubscribeArticleQuantityChange?.()
    }

    componentDidMount() {
        const { match, actions, location } = this.props
        const url = location.pathname + location.search

        actions.setDetailsUrl(getCategoryTypeFromUrl(match.url), url)

        this.initialize()
        this.subscribeToViewState()
        this.unsubscribeArticleQuantityChange = this.subscribeToArticleQuantityChange()
    }

    componentDidUpdate(prevProps: Props) {
        const {
            match: { params: prevParams },
            location: prevLocation,
            actions,
        } = prevProps
        const {
            match: { params: thisParams },
            location: thisLocation,
            state: { detailsResponse },
        } = this.props

        // Check if the url has changed
        const prevUrl = prevLocation.pathname + prevLocation.search
        const thisUrl = thisLocation.pathname + thisLocation.search
        if (prevUrl !== thisUrl) {
            actions.setDetailsUrl(getCategoryTypeFromUrl(prevProps.match.url), prevUrl)
        }

        const prevQueryParams = new URLSearchParams(prevLocation.search)
        const thisQueryParams = new URLSearchParams(thisLocation.search)

        // Get the needed information from the query params or the match params for backwards compatibility
        // TODO: remove backwards compatibility
        const prevProductGroupId = prevQueryParams.get("productGroupId") || prevParams.productGroupId || ""
        const prevSupplierId = prevQueryParams.get("supplierId") || prevParams.supplierId || ""
        const prevSupplierArticleNo = prevQueryParams.get("supplierArticleNo") || decodeURIComponent(prevParams.supplierArticleNo)
        const prevTraderArticleNo = prevQueryParams.get("traderArticleNo")
        const prevVehicleLinkageId = prevQueryParams.get("vehicleLinkageId") || ""
        const prevInitialQuantity = prevQueryParams.get("initialQuantity") || ""

        const thisProductGroupId = thisQueryParams.get("productGroupId") || thisParams.productGroupId || ""
        const thisSupplierId = thisQueryParams.get("supplierId") || thisParams.supplierId || ""
        const thisSupplierArticleNo = thisQueryParams.get("supplierArticleNo") || decodeURIComponent(thisParams.supplierArticleNo)
        const thisTraderArticleNo = thisQueryParams.get("traderArticleNo")
        const thisVehicleLinkageId = thisQueryParams.get("vehicleLinkageId") || ""
        const thisInitialQuantity = thisQueryParams.get("initialQuantity") || ""

        if (
            prevProductGroupId !== thisProductGroupId ||
            prevSupplierId !== thisSupplierId ||
            prevSupplierArticleNo !== thisSupplierArticleNo ||
            prevVehicleLinkageId !== thisVehicleLinkageId ||
            prevTraderArticleNo !== thisTraderArticleNo ||
            prevInitialQuantity !== thisInitialQuantity
        ) {
            this.setCurrentImage(0)
            this.initialize()
        }

        if (this.props.workTaskId !== prevProps.workTaskId) {
            this.currentProviders = undefined
            this.unregisterViewStateLoaded?.()
            this.unregisterViewStateUpdated?.()
            this.subscribeToViewState()
        }

        this.props.updateArticle(detailsResponse?.article)
    }

    subscribeToArticleQuantityChange() {
        return channel("WORKTASK").subscribe("BASKET/ARTICLE_QUANTITY_CHANGED", ({ article, quantity }) => {
            if (article) {
                this.props.actions.changeQuantity(article, quantity)
            }
        })
    }

    subscribeToViewState() {
        const { workTaskId } = this.props

        if (!workTaskId) {
            return
        }

        const subscription = this.ViewState_ActiveProviders.subscribe(`${encodeUniqueId(workTaskId)}__ACTIVE_VEHICLE_DATA_PROVIDERS`)

        this.unregisterViewStateLoaded = subscription.addListener("loaded", (response) => {
            this.currentProviders = response.value
        })
        this.unregisterViewStateUpdated = subscription.addListener("updated", (response) => {
            this.currentProviders = response.value
        })

        subscription.load()
    }

    initialize() {
        const {
            match,
            location,
            state: { detailsResponse },
            actions,
            history,
        } = this.props
        const { vehicle } = this.props.workTask ?? {}
        const queryParams = new URLSearchParams(location.search)
        const { viewOptions: listV2ViewOptions } = getBundleParams().listV2 ?? {}

        // Get the needed information from the query params or the match params for backwards compatibility
        // TODO: remove backwards compatibility
        const productGroupId = queryParams.get("productGroupId") || match.params.productGroupId || ""
        const supplierId = queryParams.get("supplierId") || match.params.supplierId || ""
        const supplierArticleNo = queryParams.get("supplierArticleNo") || decodeURIComponent(match.params.supplierArticleNo)
        const traderArticleNo = queryParams.get("traderArticleNo")
        const vehicleLinkageId = queryParams.get("vehicleLinkageId") || ""
        const initialQuantity = queryParams.get("initialQuantity") || ""

        // Required parameters are missing (or blank in case of backwards compatibility)
        if (!supplierId || !supplierArticleNo || supplierId === "0" || supplierArticleNo === "0") {
            // If a traderArticleNo is supplied try to ...
            if (traderArticleNo) {
                if (detailsResponse?.article?.productGroup && detailsResponse.article.supplier) {
                    const { article } = detailsResponse

                    if (article.traderArticleNo === traderArticleNo) {
                        // Loaded details in state are same so just change the url

                        // Set new query params
                        queryParams.set("productGroupId", article.productGroup.id.toString())
                        queryParams.set("supplierId", article.supplier.id.toString())
                        queryParams.set("supplierArticleNo", article.supplierArticleNo)

                        // Remove traderArticleNo from query params
                        queryParams.delete("traderArticleNo")

                        // Open new url
                        const url = `${renderRoute(match.path, {
                            ...match.params,
                            productGroupId: "0",
                            supplierId: "0",
                            supplierArticleNo: "0",
                        })}?${queryParams.toString()}`
                        history.replace(url)

                        return
                    }
                }

                /* resolve it to productGroupId, supplierId and supplierArticleNo */
                Repositories.getArticlesByWholesalerArticleNos([traderArticleNo], vehicle?.tecDocTypeId)
                    .then((response) => {
                        if (!response?.[traderArticleNo]?.length) {
                            actions.resetDetailsHead()
                            return
                        }

                        // Set new query params
                        queryParams.set("productGroupId", response[traderArticleNo][0].productGroup.id.toString())
                        queryParams.set("supplierId", response[traderArticleNo][0].supplier.id.toString())
                        queryParams.set("supplierArticleNo", response[traderArticleNo][0].supplierArticleNo)

                        // Remove traderArticleNo from query params
                        queryParams.delete("traderArticleNo")

                        // Open new url
                        const url = `${renderRoute(match.path, {
                            ...match.params,
                            productGroupId: "0",
                            supplierId: "0",
                            supplierArticleNo: "0",
                        })}?${queryParams.toString()}`
                        history.replace(url)
                    })
                    .catch(() => actions.resetDetailsHead())

                return
            }

            actions.resetDetailsHead()
            return
        }

        // Parse some string parameters to number
        const parsedProductGroupId = parseInt(productGroupId) || undefined
        const parsedSupplierId = parseInt(supplierId)
        let parsedVehicleLinkageId: number | undefined = parseInt(vehicleLinkageId)
        let parsedInitialQuantity: number | undefined = parseInt(initialQuantity)

        // Required parameters are invalid
        if (isNaN(parsedSupplierId)) {
            actions.resetDetailsHead()
            return
        }

        // If parsed vehicleLinkageId is invalid remove it
        if (isNaN(parsedVehicleLinkageId)) {
            parsedVehicleLinkageId = undefined
        }

        // If parsed initialQuantity is invalid remove it
        if (isNaN(parsedInitialQuantity)) {
            parsedInitialQuantity = undefined
        }

        if (detailsResponse?.article?.productGroup && detailsResponse.article.supplier) {
            const { article } = detailsResponse

            if (
                article.productGroup.id === parsedProductGroupId &&
                article.supplier.id === parsedSupplierId &&
                article.supplierArticleNo === supplierArticleNo &&
                article.vehicleLinkageId === parsedVehicleLinkageId &&
                article.quantity === parsedInitialQuantity
            ) {
                // Loaded details in state are same so do nothing
                return
            }
        }

        // check if details url comes from wheels bundle
        const isWheels = window.location.pathname.includes("wheels")
        const isTyres = window.location.pathname.includes("tyres")

        actions.loadDetailsHead(
            {
                productGroupId: parsedProductGroupId,
                supplierId: parsedSupplierId,
                supplierArticleNo,
                vehicleLinkageId: parsedVehicleLinkageId,
                vehicle: parsedVehicleLinkageId ? vehicle : undefined, // Only add the vehicle if a vehicleLinkageId is also present, otherwise details should be loaded without any vehicle relation
                foundBySearchTerm: queryParams.get("foundBySearchTerm") || undefined,
                initialQuantity: parsedInitialQuantity,
                overwriteInitialQuantity: isWheels || isTyres,
                disableAddToBasket: queryParams.get("disableAddToBasket") === "1",
            },
            !!listV2ViewOptions
        )
    }

    openCostTab(tabName: string) {
        bundleChannel("DETAILS").publish("NAVIGATE", { subPage: tabName })
    }

    setCurrentImage(id: number) {
        this.setState({ currentImage: id })
    }

    handleAddFirstArticleToBasket(articles: Article[]) {
        if (articles[0] && this.props.workTask?.vehicle) {
            const request = mapVehicleRecordArticleAddedToBasketEventRequest(this.props.workTask.vehicle, articles[0])
            const container: VehicleRecordsContainer = Container.getInstance(RegisteredModels.VehicleRecords) as VehicleRecordsContainer
            container.action("articleAddedToBasket")(request)
        }

        const partToReplaceId = parseQueryString(this.props.location.search).partToReplaceId as string | undefined
        if (partToReplaceId) {
            Morpheus.closeView("1")
        }
    }

    getRepairTimesUrl(article: Article, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) {
        const { repairTimeProviders } = getRepairTimeProviders()
        if (!article.productGroup || !this.props.workTask?.vehicle || !repairTimeProviders.length) {
            return
        }
        const { partsRoutes } = getBundleParams()

        let provider

        if (Array.isArray(rtProviders)) {
            let activeRTProvider = this.currentProviders?.repairTimes

            if (!activeRTProvider && this.props.userSettings) {
                activeRTProvider = this.props.userSettings.activeVehicleDataProviders.repairTimes
            }

            provider = getRepairTimesProvider(rtProviders, repairTimeProviders, activeRTProvider)
        } else {
            provider = getRepairTimesProviderStringByEnum(rtProviders)
        }

        if (!provider || !partsRoutes) {
            return
        }

        return decodeURIComponent(
            renderRoute(partsRoutes.repairTimesRoute, {
                ...this.props.match.params,
                provider,
                productGroupId: article.productGroup.id,
                supplierId: article.supplier.id,
                supplierArticleNo: article.supplierArticleNo.replace(/\//g, "%252F"),
                position: article.fittingSide,
            })
        )
    }

    getTooltipText = (article: Article) => {
        const { translate } = this.props.localization
        const supplierId = getBundleParams().articleDetailsShowSupplierIdInTooltip ? article.supplier.id : null
        const additionalInfo = article.additionalReferenceArticleInformation?.headInformation?.join(" | ")

        const supplierIdLabel = supplierId ? supplierId.pad(4, "0") : undefined

        let tooltipText: JSX.Element | undefined
        if (additionalInfo && supplierIdLabel) {
            tooltipText = (
                <span>
                    {`${additionalInfo}`} {` | ${translate(13160)} ${supplierIdLabel}`}
                </span>
            )
        } else if (supplierIdLabel) {
            tooltipText = (
                <span>
                    {translate(13160)} {supplierIdLabel}
                </span>
            )
        } else if (additionalInfo) {
            tooltipText = <span>{additionalInfo}</span>
        }
        return tooltipText
    }

    renderInfoItem = (title: string, text: ReactNode, className = "", size: Size = "s") => {
        return (
            <div className={`article-details__info__item ${className}`}>
                <SubTitle>{title}</SubTitle>
                <Headline size={size}>{text}</Headline>
            </div>
        )
    }

    renderImageThumbnail(image: Models.ArticleImage, index: number) {
        let className = "article-details__info__row__thumbnail"

        if (index === this.state.currentImage) {
            className += " article-details__info__row__thumbnail--active"
        }

        return (
            <div key={index} className={className} onClick={() => this.setCurrentImage(index)}>
                <Image type="article" url={image.thumbnailUrl} title={image.description} />
            </div>
        )
    }

    getTmaInfos(): { vehicleId?: string; customerId?: string; foundBySearchTerm?: string } {
        const { vehicle, customer } = this.props.workTask ?? {}
        const queryParams = new URLSearchParams(this.props.location.search)

        let vehicleId: string | undefined
        let foundBySearchTerm: string | undefined

        if (queryParams.has("foundBySearchTerm")) {
            foundBySearchTerm = queryParams.get("foundBySearchTerm") || undefined
        } else if (queryParams.has("vehicleLinkageId")) {
            vehicleId = vehicle?.id
        }

        return {
            vehicleId,
            customerId: customer?.id,
            foundBySearchTerm,
        }
    }

    handleDetailsButtonClick(subPage?: string, scrollTo?: string) {
        const url =
            renderRoute(this.props.match.path, {
                ...this.props.match.params,
                partsDetailsSubPage: subPage || "",
                scrollTo: scrollTo || null,
            }) + (this.props.location.search || "")
        this.props.history.push(url)
    }

    handlePrintArticleDetails() {
        const { detailsResponse } = this.props.state
        const { vehicle } = this.props.workTask ?? {}
        const {
            localization: { translateText },
        } = this.props

        if (!detailsResponse?.article) {
            return
        }

        this.setState({ isPrintLoading: true })

        printArticleDetails(
            createPrintArticleDetailsRequest(
                detailsResponse.article,
                vehicle?.tecDocTypeId,
                vehicle?.vehicleType,
                vehicle?.registrationNo,
                vehicle?.registrationTypeId
            ),
            translateText
        )
            .then(() => {
                this.setState({ isPrintLoading: false })
            })
            .catch(() => this.setState({ isPrintLoading: false }))
    }

    renderAdditionaInfo() {
        const {
            state: { detailsResponse },
            localization: { translate, translateText },
        } = this.props

        if (!detailsResponse?.article) {
            return
        }
        const { articleModificationState, additionalReferenceArticleInformation, referencedArticleModification } = detailsResponse.article

        let icon: string | undefined
        let iconClassName: string | undefined
        let message: ReactNode

        switch (articleModificationState) {
            case EArticleModificationState.ArticleAdded:
                icon = "plus"
                message = translate(888)
                break
            case EArticleModificationState.VehicleLinkageAdded:
                icon = "plus"
                message = translate(889)
                break
            case EArticleModificationState.ArticleDisabled:
                icon = "not"
                iconClassName = "icon--danger"
                message = translate(887)
                break
            case EArticleModificationState.VehicleLinkageDisabled:
                icon = "not"
                iconClassName = "icon--danger"
                message = translate(890)
                break
            default:
                break
        }

        if (!message) {
            if (referencedArticleModification?.isAddedReferencedArticle) {
                icon = "plus"
                message = translate(13116)
            } else if (referencedArticleModification?.isRemovedReferencedArticle) {
                icon = "not"
                iconClassName = "icon--danger"
                message = translate(13050)
            }
        }

        return (
            <>
                {!!additionalReferenceArticleInformation?.headInformation.length && (
                    <div className="article__description article__description--additional tooltip-wrapper">
                        <Tooltip title={translateText(886)}>
                            <Icon name="plus" />
                        </Tooltip>
                        <Text>{additionalReferenceArticleInformation.headInformation.join(" | ")}</Text>
                    </div>
                )}
                {!!message && (
                    <div className="article__information__state icon-wrapper">
                        {icon && <Icon name={icon} className={iconClassName} />}
                        {message}
                    </div>
                )}
            </>
        )
    }

    renderImage() {
        const { detailsResponse } = this.props.state
        if (!detailsResponse?.article) {
            return
        }
        const { vehicleId } = this.getTmaInfos()
        const { images, article } = detailsResponse
        const { currentImage } = this.state
        const imagesLength = images?.length ?? 0
        const image = imagesLength > currentImage ? images?.[currentImage] : null

        let thumbnailClassName = "article-details__image__thumbnail"
        if (imagesLength > 0) {
            thumbnailClassName += " article-details__image__thumbnail--clickable"
        }

        const handleImmageClick = () => {
            TmaHelper.Article.Parts.ImageClick.Click(article, vehicleId)
        }

        return (
            <div className="article-details__image">
                <ArticleImageComponent
                    thumbnailClassName={thumbnailClassName}
                    thumbnailUrl={image?.imageUrl ?? ""}
                    thumbnailDescription={image?.description}
                    internalArticleId={article.internalId}
                    vehicleLinkageId={undefined}
                    images={images}
                    enableLargeView={imagesLength > 0}
                    customThumbnailComponent={
                        <div className="article-details__image__icon">
                            <Icon name="search" size="xl" />
                        </div>
                    }
                    startIndex={currentImage}
                    onClick={handleImmageClick}
                />
            </div>
        )
    }

    renderSupplierPG(article: Article, supplierLogo: string | undefined) {
        const supplierName = article.supplier.name || "-"
        const productGroupName = article.productGroup.name || "-"

        let supplier
        if (supplierLogo && getBundleParams().showSupplierLogos) {
            supplier = <Image url={supplierLogo} className="article-details__supplier-logo" title={supplierName} />
        } else {
            supplier = supplierName
        }

        return (
            <div className="article-details__supplier-pg">
                {supplier}
                {this.renderTooltipIcon(article)}
                &nbsp;|&nbsp;
                {productGroupName}
            </div>
        )
    }

    renderTooltipIcon(article: Article) {
        const articleSupplierTooltip = this.getTooltipText(article)

        if (!articleSupplierTooltip) {
            return
        }

        return (
            <Tooltip title={articleSupplierTooltip}>
                <Icon name="info" size="24px" />
            </Tooltip>
        )
    }

    renderBonusSystems() {
        const { detailsResponse } = this.props.state

        if (!detailsResponse?.article) {
            return
        }

        const bonusSystems = getArticleBonusSystemsWithoutPoints(detailsResponse.article)

        if (!bonusSystems.length) {
            return
        }

        return bonusSystems.map((x) => (
            <BonusSystemImage key={x.id} className="article-details__bonus-system" id={x.id} name={x.name} imageUrl={x.imageUrl} />
        ))
    }

    renderInfo() {
        const { state, localization, userContext, hideWholesalerArticleNumber } = this.props
        const { detailsResponse } = state
        const { translateText } = localization

        if (!detailsResponse?.article) {
            return
        }

        const { article, images, supplierLogo } = detailsResponse

        return (
            <Stack spacing={1} mt={2} flex="3 0 0%">
                <Stack
                    direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}
                    flexWrap="wrap"
                    spacing={1}
                    justifyContent={userContext?.parameter.positionChangeEArtNrHArtNr ? "flex-end" : "flex-start"}
                >
                    {!userContext?.parameter.hideDealerPartNumber &&
                        !hideWholesalerArticleNumber &&
                        this.renderInfoItem(
                            translateText(91),
                            <ArticleNumbers wholesalerArticleNumber={article.traderArticleNo || "-"} size="large" />
                        )}
                    <Stack direction="row" spacing={2} divider={<Divider />}>
                        {this.renderInfoItem(
                            translateText(92),
                            <ArticleNumbers supplierArticleNumber={article.supplierArticleNo || "-"} size="large" />
                        )}
                        {this.renderBonusSystems()}
                    </Stack>
                </Stack>
                {this.renderInfoItem(`${translateText(71)} | ${translateText(88)}`, this.renderSupplierPG(article, supplierLogo))}
                {!!article.articleModificationState ||
                    !!article.additionalReferenceArticleInformation?.headInformation.length ||
                    article.referencedArticleModification?.isAddedReferencedArticle ||
                    (article.referencedArticleModification?.isRemovedReferencedArticle && (
                        <>{this.renderInfoItem(translateText(526), this.renderAdditionaInfo(), undefined, "xs")}</>
                    ))}
                <Box display="flex" flex="0 1 32%" sx={{ overflowX: "auto", overflowY: "hidden" }}>
                    {(images?.length ?? 0) > 1 && images?.map((img, id) => this.renderImageThumbnail(img, id))}
                </Box>
            </Stack>
        )
    }

    renderAddToBasket(showButtonText = false) {
        const {
            showBasketButton,
            state: { detailsResponse },
            match,
        } = this.props
        const partToReplaceId = parseQueryString(this.props.location.search).partToReplaceId as string | undefined

        if (!detailsResponse?.article?.showAddToBasket || getBundleParams().hideAddToBasketButton || !showBasketButton) {
            return
        }

        const { vehicleId, customerId, foundBySearchTerm } = this.getTmaInfos()

        if (match.url.includes("wheels")) {
            return (
                <div style={{ display: "flex" }}>
                    {this.props.renderMicro!("basket", "add-to-basket", {
                        data: [detailsResponse.article],
                        vehicleId,
                        customerId,
                        foundBySearchTerm,
                        hideBasketButton: true,
                        erpType: "details",
                        origin: TmaEModule.ARTICLE_DETAILS,
                        // on wheels we need only the amount field from add-to-shopping-basket
                    })}
                    {this.props.renderMicro!("wheels", "goto-overview-button", {
                        article: detailsResponse.article,
                        isInSensorDetails: match.url.includes("sensor"),
                    })}
                </div>
            )
        }

        return this.props.renderMicro!("basket", "add-to-basket", {
            data: [detailsResponse.article],
            vehicleId,
            customerId,
            foundBySearchTerm,
            onAddToBasket: this.handleAddFirstArticleToBasket, // Only one article was passed to the micro so we can safely use the first one only here.
            onAddCatalogArticleToBasketFinished: (response) => this.props.onFinished(response),
            buttonText: showButtonText ? this.props.localization.translateText(133) : undefined,
            erpType: "details",
            shownWithWarehouseSelector: this.props.showArticleWarehouseSelector,
            partToReplaceId,
            origin: TmaEModule.ARTICLE_DETAILS,
        })
    }

    renderCostEstimationButton(largeButton = false) {
        const { repairTimeProviders } = getRepairTimeProviders()
        const {
            state: { detailsResponse },
            match,
        } = this.props

        // no active costestimation module
        if (!this.props.hasCostestimation) {
            return
        }

        if (match.url.includes("wheels") || isCentralOrder() || !repairTimeProviders.length || getBundleParams().hideCostEstimationButton) {
            return
        } // if details is used inside wheels toolkit -> cost estimation button should be hidden

        if (!detailsResponse?.article?.showAddToBasket || !match.params.workTaskId) {
            return
        }

        const { vehicleId, customerId, foundBySearchTerm } = this.getTmaInfos()
        // micro add-to-cost-estimate
        return this.props.renderMicro!("basket", "add-to-cost-estimate", {
            data: [detailsResponse.article],
            vehicleId: vehicleId ?? this.props.workTask?.vehicle?.id, // Set always the vehicle id from the worktask to avoid multiple erp calls https://jira.dvse.de/browse/NEXT-24210
            customerId,
            foundBySearchTerm,
            variant: largeButton ? "large" : "normal",
            repairTimeProviders,
            onAddToBasket: this.handleAddFirstArticleToBasket, // Only one article was passed to the micro so we can safely use the first one only here.
            getRepairTimesUrl: this.getRepairTimesUrl,
            erpType: "details",
        })
    }

    renderPrintArticleDetails(largeButton = false) {
        return (
            <Box width={largeButton ? "6.25em" : undefined}>
                <Box zIndex={1} position="relative">
                    {this.state.isPrintLoading && <Badge title="loading" skin="dark" loading />}
                </Box>
                <Button startIcon={<Icon name="print" />} fullWidth onClick={this.handlePrintArticleDetails} />
            </Box>
        )
    }

    renderArticleWarehouseSelector() {
        const {
            state: { detailsResponse },
            match,
            showArticleWarehouseSelector,
        } = this.props

        if (!showArticleWarehouseSelector || !detailsResponse?.article?.showAddToBasket || !match.params.workTaskId) {
            return
        }

        // micro article-warehouse-selector
        return this.props.renderMicro!("erp", "article-warehouse-selector", { data: detailsResponse.article })
    }

    renderBonusPoints() {
        const {
            state: { detailsResponse },
            localization: { translateText },
        } = this.props

        if (!detailsResponse?.article) {
            return
        }

        const bonusSystems = getArticleBonusSystemsWithPoints(detailsResponse.article)

        if (!bonusSystems.length) {
            return
        }

        return (
            <Box className="article-details__actions__bonus-points" mr={1} alignSelf="center">
                {bonusSystems.map((x) => (
                    <BonusPoints
                        key={x.id}
                        bonusSystem={{
                            ...x,
                            name: translateText(13113),
                        }}
                    />
                ))}
            </Box>
        )
    }

    renderErpInformation() {
        const { detailsResponse } = this.props.state
        if (!detailsResponse?.article) {
            return
        }

        const { vehicleId, foundBySearchTerm } = this.getTmaInfos()

        return (
            <ErpInformation
                article={detailsResponse.article}
                foundBySearchTerm={foundBySearchTerm}
                foundByVehicleId={vehicleId}
                handleAlternativesClick={() => this.handleDetailsButtonClick("alternative-articles")}
                handleReplacementArticlesClick={() => this.handleDetailsButtonClick("replacement-articles")}
                handleGraduatedPricesClick={() => this.handleDetailsButtonClick("stocks", "prices")}
            />
        )
    }

    renderAvailabilityOverview() {
        const { renderMicro, infoAvailabilityOverviewData } = this.props

        if (!infoAvailabilityOverviewData) {
            return null
        }

        return (
            <div className="article-details__actions__availability-overview">
                {renderMicro!("erp", "erp-info-availability-overview", {
                    data: infoAvailabilityOverviewData,
                })}
            </div>
        )
    }

    renderAvailability() {
        const { detailsResponse } = this.props.state
        if (!detailsResponse?.article) {
            return
        }

        const { vehicleId, foundBySearchTerm } = this.getTmaInfos()

        return this.props.renderMicro!("erp", "details-erp-info-availability", {
            data: detailsResponse.article,
            foundBySearchTerm,
            foundByVehicleId: vehicleId,
        })
    }

    renderAddToCompilations() {
        const { detailsResponse } = this.props.state

        if (!!detailsResponse?.article && detailsResponse?.article?.showAddToBasket) {
            return <AddToCompilations article={detailsResponse.article} />
        }

        return null
    }

    renderPrices(priceNamesInTooltip?: boolean) {
        const { detailsResponse } = this.props.state
        const isWheels = window.location.pathname.includes("wheels")

        if (isWheels && !this.props.userSettings?.showPurchasePrice) {
            return null
        }

        if (!detailsResponse?.article) {
            return
        }

        const { foundBySearchTerm } = this.getTmaInfos()

        return this.props.renderMicro!("erp", "details-erp-info-prices", {
            data: detailsResponse.article,
            foundBySearchTerm,
            priceNamesInTooltip,
        })
    }

    renderGraduatedPriceInfo() {
        const { detailsResponse } = this.props.state

        if (!detailsResponse?.article) {
            return
        }

        const { foundBySearchTerm } = this.getTmaInfos()

        return this.props.renderMicro!("erp", "details-erp-info-graduated", {
            data: detailsResponse.article,
            foundBySearchTerm,
            onClick: () => this.handleDetailsButtonClick("stocks", "prices"),
        })
    }

    renderQuantityUnit() {
        const { detailsResponse } = this.props.state
        const isWheels = window.location.pathname.includes("wheels")

        if (isWheels && !this.props.userSettings?.showPurchasePrice) {
            return null
        }

        if (!detailsResponse?.article) {
            return
        }

        const { foundBySearchTerm } = this.getTmaInfos()

        return this.props.renderMicro!("erp", "details-erp-info-quantity-unit", { data: detailsResponse.article, foundBySearchTerm })
    }

    renderSpecialIcons() {
        const { detailsResponse } = this.props.state

        if (!detailsResponse?.article) {
            return
        }

        const { foundBySearchTerm } = this.getTmaInfos()

        return this.props.renderMicro!("erp", "details-erp-info-special-icons", { data: detailsResponse.article, foundBySearchTerm })
    }

    handleQuantityChange(amount: AmountItem) {
        const { detailsResponse } = this.props.state
        if (!detailsResponse?.article) {
            return
        }

        this.props.actions.changeQuantity(detailsResponse.article, amount.value)
    }

    renderStandAloneButtons() {
        const {
            renderMicro,
            state: { detailsResponse },
            localization: { translateText },
            userContext,
            workTask,
        } = this.props
        const { partsRoutes } = getBundleParams()

        if (!detailsResponse?.article || !partsRoutes) {
            return
        }

        const quantityValue = detailsResponse.article.quantity !== detailsResponse.article.initialQuantity ? detailsResponse.article.quantity : 4
        const { repairTimeProviders } = getRepairTimeProviders()

        return (
            <div className="standalone-basket-elements">
                <AmountField className="add-to-basket__quantity" value={quantityValue ?? 4} onChange={this.handleQuantityChange} />

                {userContext?.system.systemType === SystemType.Redesign &&
                    renderMicro!("standalone", "rd-add-articles-to-basket", {
                        items: [detailsResponse.article],
                        buttonText: translateText(133),
                        sourceId: "TM_TYRES",
                    })}

                {workTask?.vehicle &&
                    renderMicro!("standalone", "open-rt-modal", {
                        repairTimesRoute: partsRoutes.repairTimesRoute,
                        items: [detailsResponse.article],
                        repairTimeProviders,
                        sourceId: "TM_TYRES",
                    })}
            </div>
        )
    }

    renderNextActionsButtons(article: Article | undefined) {
        const costs = this.props.localization.currency(
            this.props.selectedOrderOption?.orderOption?.costs?.value || 0,
            this.props.selectedOrderOption?.orderOption?.costs?.currencySymbol || ""
        )

        const showSpecialProducmentInfo =
            this.props?.selectedOrderOption?.orderOption?.costs && this.props?.selectedOrderOption?.orderOption?.costs?.value > 0

        const tabName = article?.useSpecialProcurement ? "special-procurement" : "stocks"

        return (
            <Stack>
                {this.props.renderMicro!("erp", "teccom-basket-listener")}
                <Stack direction="row" spacing={0.5}>
                    {this.renderArticleWarehouseSelector()}

                    {this.renderAddToBasket()}

                    {this.renderCostEstimationButton()}
                    {this.renderPrintArticleDetails()}
                    {this.renderAddToCompilations()}
                </Stack>
                <Box display="flex" justifyContent="flex-end">
                    {showSpecialProducmentInfo && (
                        <Box display="flex" alignItems="center" py={1} pr={2} onClick={() => this.openCostTab(tabName)} sx={{ cursor: "pointer" }}>
                            <Typography variant="body2" pr={1}>
                                +{`${costs} - ${this.props.selectedOrderOption?.orderOption?.description}`}
                            </Typography>
                            <Icon name="purchasing-costs" color="highlight" />
                        </Box>
                    )}
                </Box>
            </Stack>
        )
    }

    handleNoteActionButtonClick() {
        const url =
            renderRoute(this.props.match.path, { ...this.props.match.params, partsDetailsSubPage: "notes" }) + (this.props.location.search || "")
        this.props.history.push(url)
    }

    renderDefault() {
        const {
            match: { url },
            userContext,
        } = this.props
        const insideTyresStandalone = userContext?.system.systemType !== SystemType.Next && url.includes("tyres/details")
        const insideWheelsStandalone = userContext?.system.systemType !== SystemType.Next && url.includes("wheels-details")
        const showNotes = !!(getBundleParams().enableArticleNotes && this.props.state.detailsResponse?.article)

        return (
            <PanelSection className="article-details__head">
                {this.renderImage()}
                {this.renderInfo()}
                <div className="article-details__actions">
                    <div className="article-details__actions__basket">
                        {insideTyresStandalone || insideWheelsStandalone
                            ? this.renderStandAloneButtons()
                            : this.renderNextActionsButtons(this.props.state.detailsResponse?.article)}
                    </div>
                    <div className="article-details__actions__erp">
                        {showNotes && (
                            <Box display="flex" justifyContent="flex-end" flexDirection="column" sx={{ m: "0.5em" }}>
                                <NoteActionButton
                                    article={this.props.state.detailsResponse!.article!}
                                    layout={["ghost"]}
                                    handleOnNoteClick={this.handleNoteActionButtonClick}
                                    vehicle={this.props.workTask?.vehicle}
                                />
                            </Box>
                        )}
                        {this.renderBonusPoints()}
                        {this.renderErpInformation()}
                    </div>

                    {this.renderAvailabilityOverview()}
                </div>
            </PanelSection>
        )
    }

    render() {
        const { detailsResponse } = this.props.state

        if (detailsResponse && !detailsResponse.article) {
            return (
                <PanelSection className="article-details__head article-details__head--loader">
                    <Headline size="s">{this.props.localization.translate(163)}</Headline>
                </PanelSection>
            )
        }

        // Get the needed information from the query params or the match params for backwards compatibility
        // TODO: remove backwards compatibility
        const queryParams = new URLSearchParams(this.props.location.search)
        const supplierId: number = parseInt(queryParams.get("supplierId") || this.props.match.params.supplierId || "")
        const supplierArticleNo: string | null = queryParams.get("supplierArticleNo") || decodeURIComponent(this.props.match.params.supplierArticleNo)

        if (
            !detailsResponse?.article ||
            detailsResponse.article.supplier.id !== supplierId ||
            detailsResponse.article.supplierArticleNo !== supplierArticleNo
        ) {
            return (
                <PanelSection className="article-details__head article-details__head--loader">
                    <Headline size="s">{this.props.localization.translate(201)}</Headline>
                    <Loader delay={0} />
                </PanelSection>
            )
        }

        const template = getBundleParams().templates?.detailsHead
        let content = null

        if (template) {
            content = Morpheus.renderTemplate(template.bundle, template.template, this)
        }

        if (!content) {
            content = this.renderDefault()
        }

        return content
    }
}
