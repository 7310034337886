import { useQuery } from "react-query"
import { useHandlePostSendOrderAction } from "../../hooks/useHandlePostSendOrderAction"
import * as Data from ".."
import { useBasketUpdateWorkflow } from "./workTaskBasket/workflow/useBasketUpdateWorkflow"

export function useThirdPartyBasket(workTaskId: string, handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { handlePostSendOrderAction, externalModalUrl, resetExternalModalUrl } = useHandlePostSendOrderAction()
    const {
        data: importExternalBasketResponse,
        isError: importError,
        refetch: importFromExternalBasket,
        isFetching: importFetching,
    } = useQuery({
        queryKey: ["basket_importFromExternalBasket", workTaskId],
        queryFn: () => {
            return Data.importFromExternalBasket(workTaskId)
        },
        enabled: false,
        onSuccess: (response) => {
            if (response?.basketUpdateWorkflow) {
                handleBasketUpdateWorkflow(workTaskId, response)
            }
        },
    })

    const {
        data: exportExternalBasketResponse,
        isError: exportError,
        refetch: exportToExternalBasket,
        isFetching: exportFetching,
    } = useQuery({
        queryKey: ["basket_exportToExternalBasket", workTaskId],
        queryFn: () => {
            return Data.exportToExternalBasket(workTaskId)
        },
        enabled: false,
        onSuccess: (response) => {
            if (response?.basketUpdateWorkflow) {
                handleBasketUpdateWorkflow(workTaskId, response)
            }
            if (response?.postSendOrderActions) {
                handlePostSendOrderAction(response.postSendOrderActions)
            }
        },
    })

    return {
        importFromExternalBasket,
        exportToExternalBasket,
        externalBasketLoading: importFetching || exportFetching,
        externalBasketPostSendActions: exportExternalBasketResponse?.postSendOrderActions,
        externalBasketErrorDetails: importExternalBasketResponse?.errorDetails ?? exportExternalBasketResponse?.errorDetails,
        externalBasketHasTechnicalErrors: importError || exportError,
        externalModalUrl,
        resetExternalModalUrl,
    }
}
