import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute, uniqueId, encodeUniqueId, concat, useHeighAdjustment, useMultiWidgetState, useWindowSize } from "@tm/utils"
import { Widget, WidgetSizes } from "@tm/controls"
import { AdvertisementCategory, channel } from "@tm/models"
import { useHistory, useParams } from "react-router"
import { Icon, Box, Loader, Stack, SxProps, Theme, styled, LinkButton } from "@tm/components"
import { ConfigQueryParams } from "../shared"
import { getBundleParams } from "../../utils"
import { OffersFrame } from "./components/OffersFrame"
import { OffersWidgetPagination } from "./components/OffersWidgetPagination"
import { openPartsList } from "./business/helpers"
import { OfferTabs } from "./components/OfferTabs"
import { useAdvertisementCategories } from "./business/useAdvertisementCategories"
import { useAdvertisementParts } from "./business/useAdvertisementParts"
import { useDisabledFunction } from "../../hooks/useDisabledFuntion"
import { OfferItem } from "./components/OfferItem"

type Props = {
    showNormalOffers: boolean
    showCarouselOffers?: boolean
    params: ConfigQueryParams
    className?: string
    widgetSize?: WidgetSizes
    height?: number
    hideShowAllButton?: boolean
    disabledFunction?: string
    alignHeightToWidgets?: string[]
    showComponentKey?: string
    storeId?: string
    variant?: "onlyContent" | "widget"
    sx?: SxProps<Theme>
}

const GENERATED_WORKTASK_ID = uniqueId()
const MAX_NAVIGATION_PAGES = 10

const SxWidget = styled(Widget)({})

export function WidgetFrame(props: Props) {
    const {
        showComponentKey,
        showNormalOffers,
        showCarouselOffers,
        className,
        widgetSize,
        height,
        params: configParams,
        hideShowAllButton,
        variant,
        storeId,
        sx,
    } = props

    const { detailsUrl: detailsRoute, tabTitle } = getBundleParams()
    const { isDisabled } = useDisabledFunction({ disabledFunction: props.disabledFunction })
    const { translateText } = useLocalization()
    const history = useHistory()
    const params = useParams<{ workTaskId?: string }>()
    const removeHeightAdjustment = useRef<() => void>()

    const [selectedMultiwidgetTab] = useMultiWidgetState({ storeId })

    useEffect(() => {
        return () => {
            removeHeightAdjustment.current?.()
        }
    }, [])

    const detailsUrl = useMemo(
        () => renderRoute(detailsRoute, { workTaskId: params.workTaskId ?? encodeUniqueId(GENERATED_WORKTASK_ID) }),
        [detailsRoute, params.workTaskId]
    )

    const handleRef = (el: HTMLDivElement | null) => {
        const { alignHeightToWidgets } = props
        if (el && alignHeightToWidgets) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            removeHeightAdjustment.current = useHeighAdjustment().setHeightAdjustment(el, alignHeightToWidgets)
        }
    }

    const {
        data: advertisementCategories = [],
        isLoading: advertisementCategoriesLoading,
        isFetchedAfterMount: advertisementCategoriesInitialized,
    } = useAdvertisementCategories(showCarouselOffers)

    const normalOffersCategory: AdvertisementCategory = useMemo(
        () => ({
            id: "IFRAME-OFFERS",
            description: translateText(1276),
        }),
        [translateText]
    )

    const tabItems = useMemo(
        () => (showNormalOffers ? [normalOffersCategory, ...advertisementCategories] : advertisementCategories),
        [showNormalOffers, normalOffersCategory, advertisementCategories]
    )
    const [activeCategory, setActiveCategory] = useState(showNormalOffers ? normalOffersCategory : undefined)
    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const normalOffersTabActive = activeCategory?.id === normalOffersCategory.id

    const [windowWidth] = useWindowSize()

    const itemAmount = useMemo(() => {
        if (variant !== "onlyContent") {
            return 3
        }

        switch (true) {
            case windowWidth < 1440:
                return 2
            case windowWidth < 1680:
                return 3
            case windowWidth < 1899:
                return 4
            case windowWidth > 1900:
                return 5
            default:
                return 3
        }
    }, [variant, windowWidth])

    const {
        advertisementItems,
        parts,
        isLoading: advertisementItemsLoading,
        totalPages,
    } = useAdvertisementParts(activeCategory?.id !== normalOffersCategory.id ? activeCategory?.id : undefined, currentPageIndex, itemAmount)

    useEffect(() => {
        if (!showNormalOffers && advertisementCategoriesInitialized && advertisementCategories.length) {
            setActiveCategory(advertisementCategories[0])
        }
    }, [showNormalOffers, advertisementCategoriesInitialized, advertisementCategories])

    const handleChangeCategory = useCallback((category) => {
        setActiveCategory(category)
        setCurrentPageIndex(0)
    }, [])

    function requestPartList() {
        if (activeCategory) {
            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1728), skin: "success" })
            openPartsList(history, GENERATED_WORKTASK_ID, activeCategory, tabTitle)
        }
    }

    function renderWidgetHeader() {
        return (
            <Widget.Header key="widget__header">
                <Widget.Header.Title>
                    <Icon name="sales" size="s" className="widget__icon" />
                    <Widget.Header.Title.Text>{translateText(1276)}</Widget.Header.Title.Text>
                </Widget.Header.Title>
                <Widget.Header.Title.Options>
                    {!hideShowAllButton && (showNormalOffers || (showCarouselOffers && advertisementCategories.length)) && renderShowAllButton()}
                </Widget.Header.Title.Options>
            </Widget.Header>
        )
    }

    function renderPagination() {
        return (
            <>
                {!normalOffersTabActive && !!advertisementItems.length && (
                    <OffersWidgetPagination
                        currentPageIndex={currentPageIndex}
                        onChangePageIndex={setCurrentPageIndex}
                        totalPages={totalPages}
                        disabled={advertisementItemsLoading}
                        maxPages={MAX_NAVIGATION_PAGES}
                    />
                )}
            </>
        )
    }

    function renderWidgetFooter() {
        return (
            <Widget.Footer key="widget__footer">
                <div className="widget-footer">{renderPagination()}</div>
            </Widget.Footer>
        )
    }

    function renderOffersContent() {
        if (normalOffersTabActive) {
            return <OffersFrame params={configParams} />
        }

        return (
            <Box height="100%">
                {(advertisementCategoriesLoading || advertisementItemsLoading) && <Loader />}
                {!advertisementCategoriesLoading && !advertisementItemsLoading && !advertisementItems.length && translateText(1010)}

                {!!activeCategory?.id && !!parts.length && (
                    <Stack direction="row" spacing={1} flex={1} mx={1} pt={0.5} pb={1}>
                        {parts.map((part) => (
                            <OfferItem
                                key={part.id}
                                workTaskId={GENERATED_WORKTASK_ID}
                                part={part}
                                advertisementCategoryId={activeCategory.id}
                                variant={variant}
                                onRequestPartList={requestPartList}
                            />
                        ))}
                    </Stack>
                )}
            </Box>
        )
    }

    function updateWorktaskTitle() {
        if (!activeCategory) {
            return
        }

        channel("WORKTASK", GENERATED_WORKTASK_ID).publish("MODULE/CHANGED", {
            id: activeCategory.id,
            name: activeCategory.description,
        })
    }

    function renderShowAllButton() {
        return (
            <LinkButton
                to={normalOffersTabActive ? detailsUrl : ""}
                onClick={!normalOffersTabActive ? () => requestPartList() : () => updateWorktaskTitle()}
                size="small"
            >
                {translateText(1275)}
            </LinkButton>
        )
    }

    function renderContent() {
        return (
            <Box flex={1} height={height || "20em"}>
                {showCarouselOffers && (
                    <OfferTabs
                        loading={advertisementCategoriesLoading}
                        advertisementCategories={tabItems}
                        activeCategory={activeCategory}
                        onChangeCategory={handleChangeCategory}
                        components={variant === "onlyContent" ? "MUI" : "Controls"}
                    />
                )}

                {renderOffersContent()}
            </Box>
        )
    }

    if (showComponentKey && selectedMultiwidgetTab !== showComponentKey) {
        return null
    }

    if (variant === "onlyContent") {
        return (
            <Stack flex={1} height="30em">
                {renderContent()}
                <Box display="flex" justifyContent="center" width="100%">
                    {renderPagination()}
                </Box>
                <Box justifyContent="right" display="flex" mr={1}>
                    {renderShowAllButton()}
                </Box>
            </Stack>
        )
    }

    if (isDisabled) {
        return null
    }

    return (
        <SxWidget
            sx={sx}
            size={widgetSize ?? "4x4"}
            active
            className={concat(" ", "tk-offers", "widget-frame", className)}
            title={translateText(1276)}
            iconName="sales"
            header={renderWidgetHeader()}
            footer={renderWidgetFooter()}
            height={height}
            forwardedRef={handleRef}
        >
            {renderContent()}
        </SxWidget>
    )
}
