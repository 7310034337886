import { ajaxAuth } from "@tm/utils"
import { getAuthorityServiceUrl } from ".."
import { ChangeUserEmailResponse } from "../model/user"

export function setUser(firstname: string, lastname: string, id: number) {
    const url = `${getAuthorityServiceUrl()}/user/SetUser`
    // id = MDM LfdNr
    const body = { firstname, lastname, id }

    return ajaxAuth({ url, body, method: "POST" })
}

export function changeUserEmail(email: string, hostname: string, identifier: string, username: string) {
    const url = `${getAuthorityServiceUrl()}/user/ChangeUserEmail`
    const body = { email, hostname, identifier, username }

    return ajaxAuth<ChangeUserEmailResponse>({ url, body, method: "POST" })
}
