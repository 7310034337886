import { Article, DefaultArticleItemState, OE } from "@tm/models"
import { useMemo } from "react"
import { useDisableReason } from "./useDisableReason"
import { usePartsViewOptions } from "../usePartsViewOptions"
import { useListOptionsContext } from "../../ContextProvider"
import { usePartsAlternativesModuleState } from "../../PartsAlternatives/PartsAlternativesModuleState"

export function useArticleOptions(article: Article): DefaultArticleItemState["options"] {
    const options = useListOptionsContext()

    const partToReplaceId = usePartsAlternativesModuleState((x) => x?.partToReplaceId)

    const partToReplaceOptions = useMemo(() => {
        if (partToReplaceId) {
            return {
                ...options,
                hideAddToBasketButton: true,
                hideCostEstimationButton: true,
                showReplaceButton: false,
            }
        }
        return options
    }, [options, partToReplaceId])

    const {
        partsViewSettings: { compactView, quantitySuggestionEnabled },
    } = usePartsViewOptions()
    const hasSuggestedQuantity = quantitySuggestionEnabled && !!article.suggestedQuantity

    const disableReason = useDisableReason(article)

    return useMemo(
        () => ({
            ...partToReplaceOptions,
            compactView,
            hasSuggestedQuantity,
            disableReason,
        }),
        [partToReplaceOptions, disableReason, compactView, hasSuggestedQuantity]
    )
}

export function useOeArticleOptions(): DefaultArticleItemState["options"] {
    const options = useListOptionsContext()
    const partToReplaceId = usePartsAlternativesModuleState((x) => x?.partToReplaceId)

    const partToReplaceOptions = useMemo(() => {
        if (partToReplaceId) {
            return {
                ...options,
                hideAddToBasketButton: true,
                hideCostEstimationButton: true,
                showReplaceButton: false,
            }
        }
        return options
    }, [options, partToReplaceId])

    const {
        partsViewSettings: { compactView },
    } = usePartsViewOptions()

    return useMemo(
        () => ({
            ...partToReplaceOptions,
            compactView,
        }),
        [partToReplaceOptions, compactView]
    )
}
