import { useCallback, useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { Article, IMicros } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { useOpenArticleDetails } from "@tm/utils"
import { CardContent, ArticleNumber, Box, Stack, Typography, Image, Icon, Button, Tooltip } from "@tm/components"
import { getBundleParams } from "../../../utils"
import { OfferCard, OfferCardPartActions } from "./StyledComponents"

type Props = {
    workTaskId: string
    part: Article
    advertisementCategoryId: string
    onRequestPartList(showBasket: boolean): void
}

export function OfferItemCatalogPartWide({ workTaskId, part, advertisementCategoryId, onRequestPartList }: Props) {
    const { userContext } = useUser() ?? {}
    const { renderMicro } = useMicro<IMicros>()
    const { partsDetailsModalUrl } = getBundleParams()

    const basketParts = useMemo(() => (part ? [part] : []), [part])

    const requestPartList = useCallback(() => onRequestPartList(true), [onRequestPartList])

    const handleOpenArticleDetails = useOpenArticleDetails({
        workTaskId,
        productGroupId: part.productGroup.id,
        supplierId: part.supplier.id,
        supplierArticleNumber: part.supplierArticleNo,
        partsDetailsUrl: partsDetailsModalUrl,
        openModal: Morpheus.showView,
    })

    function openDetails(subPage?: string, scrollTo?: string) {
        setTimeout(() => {
            handleOpenArticleDetails(subPage, scrollTo)
        }, 5000)

        requestPartList()
    }

    return (
        <OfferCard key={part.id}>
            <CardContent sx={{ flex: 1 }}>
                <Box mt={0.5} height="6em" display="flex" alignItems="center" justifyContent="center">
                    <Image src={part.thumbnail} />
                </Box>
                <Stack mt={1}>
                    <Box height="3em">
                        <Tooltip title={part.productGroup.name} showOnlyOnOverflow withoutBordercolor>
                            <Typography variant="body1" fontWeight="bold" overflow="hidden" textOverflow="ellipsis">
                                {part.productGroup.name}
                            </Typography>
                        </Tooltip>
                    </Box>
                    <Box height="2em">
                        <Typography variant="body2" maxLines={2}>
                            {part.supplier.name}
                        </Typography>
                    </Box>
                </Stack>
                <Stack
                    alignItems={userContext?.parameter.positionChangeEArtNrHArtNr ? "flex-end" : "flex-start"}
                    direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}
                    height="3em"
                >
                    {part.traderArticleNo && !userContext?.parameter.hideDealerPartNumber && (
                        <ArticleNumber
                            articleNumber={part.traderArticleNo}
                            size="medium"
                            articleNumberType="wholesaler"
                            omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                            onClick={openDetails}
                        />
                    )}
                    {part.supplierArticleNo && (
                        <ArticleNumber
                            articleNumber={part.supplierArticleNo}
                            size="medium"
                            articleNumberType="supplier"
                            omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                            onClick={openDetails}
                        />
                    )}
                </Stack>
                <Box position="relative" display="flex" justifyContent="start" height="2em">
                    {renderMicro("erp", "erp-info-prices", {
                        data: part,
                    })}
                    {renderMicro("erp", "erp-info-availability", {
                        data: part,
                        onClick: (useSpecialProcurement?: boolean) => {
                            if (useSpecialProcurement) {
                                openDetails("special-procurement", "#load-teccom")
                            } else {
                                openDetails("stocks")
                            }
                        },
                        onTeccomClick: (useSpecialProcurement: boolean) => {
                            if (useSpecialProcurement) {
                                openDetails("special-procurement", "#load-teccom")
                            } else {
                                openDetails("stocks", "teccom")
                            }
                        },
                    })}
                </Box>
                <Box>
                    {renderMicro("erp", "erp-info-additional-information", {
                        data: part,
                        maxLines: 1,
                        variant: "body2",
                    })}
                </Box>
            </CardContent>
            <OfferCardPartActions>
                {renderMicro("basket", "add-to-basket", {
                    data: basketParts,
                    generatedWorktaskId: workTaskId,
                    hideQuantityField: false,
                    advertisementCategoryId,
                    onAddCatalogArticleToBasketFinished: requestPartList,
                })}
                <Button startIcon={<Icon name="details" />} onClick={() => openDetails()} />
            </OfferCardPartActions>
        </OfferCard>
    )
}
