import { ArticleItem, Box, Icon, MenuItem, Select, Stack, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleInfoType, channel } from "@tm/models"
import { useState } from "react"
import { createRequestDirectSearchArticleListPayload, createRequestUniversalSearchArticleListPayload } from "@tm/utils"
import { PartToReplaceConfigurationProvider } from "../../ArticleListConfiguration"
import { DefaultArticleItem } from "../DefaultArticleItem"
import { OeArticleItemStateProvider } from "../OeArticle/OeArticleItemStateProvider"
import { usePartsAlternativesModuleState } from "../../PartsAlternatives/PartsAlternativesModuleState"
import { SearchType } from "../../../../business"

const StyledBox = styled(Box)({
    borderBottomWidth: "0.5rem",
    borderBottomStyle: "solid",
    padding: "0.5rem 1rem 4rem 1rem",
    background: "#fbe3b2",
    borderBottomColor: "#f8d182",
})

const ArticleWrapper = styled(Box)({
    margin: "-4.5rem 0 0 2rem",
    padding: ".5em .25em",
})

type Props = {
    searchType: SearchType
}

type DropdownOption = { value: SearchType.UNISEARCH | SearchType.DIRECT; text: string }

export const PartToReplace = ({ searchType }: Props) => {
    const { translateText } = useLocalization()
    const article = usePartsAlternativesModuleState((x) => x?.partToReplaceData?.article)
    const oeArticle = usePartsAlternativesModuleState((x) => x?.partToReplaceData?.oeArticle)
    const orderItem = usePartsAlternativesModuleState((x) => x?.partToReplaceData?.orderItem)
    const articleInfoType = usePartsAlternativesModuleState((x) => x?.partToReplaceData?.articleInfoType)
    const searchTypeOptions: Array<DropdownOption> = [
        { value: SearchType.DIRECT, text: translateText(840) },
        { value: SearchType.UNISEARCH, text: translateText(1009) },
    ]
    const [currentSearchType, setCurrentSearchType] = useState<SearchType | undefined>(searchTypeOptions.find((x) => x.value === searchType)?.value)

    if (!article && !oeArticle) {
        return null
    }

    const handleSearchTypeChange = (selectedSearchType: SearchType) => {
        setCurrentSearchType(selectedSearchType)
        if (orderItem) {
            const articleNumber =
                orderItem.articleInfo.oeArticleNumber || orderItem.articleInfo.articleNumber || orderItem.articleInfo.wholesalerArticleNumber || ""
            const partToReplaceId = orderItem.id

            if (selectedSearchType === SearchType.DIRECT) {
                channel("WORKTASK").publish("PARTS/REQUEST_LIST", createRequestDirectSearchArticleListPayload(articleNumber, partToReplaceId))
            }

            if (selectedSearchType === SearchType.UNISEARCH) {
                channel("WORKTASK").publish("PARTS/REQUEST_LIST", createRequestUniversalSearchArticleListPayload(articleNumber, partToReplaceId))
            }
        }
    }

    return (
        <Box>
            {articleInfoType === ArticleInfoType.OeArticle && oeArticle && (
                <Box>
                    <Stack direction="row" gap={0.5} padding={1} alignItems="center" sx={{ backgroundColor: "#e2e2e2" }}>
                        <Typography>{translateText(1508)}</Typography>
                        <Select value={currentSearchType} onChange={(e) => handleSearchTypeChange(e.target.value as SearchType)}>
                            {searchTypeOptions?.map((cat) => (
                                <MenuItem key={cat.value} value={cat.value}>
                                    {translateText(cat.text)}
                                </MenuItem>
                            )) || []}
                        </Select>
                    </Stack>
                    <StyledBox>
                        <Stack direction="row" gap={0.5} padding={1} alignItems="center">
                            <Icon name="info" size="26px" />
                            <Typography>{translateText(1505)}</Typography>
                        </Stack>
                    </StyledBox>
                    <ArticleWrapper>
                        <OeArticleItemStateProvider article={oeArticle}>
                            <ArticleItem variant="OE" />
                        </OeArticleItemStateProvider>
                    </ArticleWrapper>
                </Box>
            )}
            {articleInfoType === ArticleInfoType.TecdocArticle && article && (
                <Box>
                    <StyledBox>
                        <Stack direction="row" gap={0.5} padding={0.5} alignItems="center">
                            <Icon name="info" size="26px" />
                            <Typography>{translateText(1505)}</Typography>
                        </Stack>
                    </StyledBox>
                    <ArticleWrapper>
                        <Stack direction="row" gap={0.5} padding={0.5} alignItems="center">
                            <Icon name="info" size="20px" />
                            <Typography variant="label">{translateText(12893)}</Typography>
                        </Stack>
                        <PartToReplaceConfigurationProvider>
                            <DefaultArticleItem key={article.id} article={article} />
                        </PartToReplaceConfigurationProvider>
                    </ArticleWrapper>
                </Box>
            )}
        </Box>
    )
}
