import { Article, GetArticlesRequest } from "@tm/models"
import { QueryFunctionContext } from "react-query"
import * as PartsRepository from "../../../data/repositories/parts"
import { ArticlePage, QueryKey } from "../hooks/useArticlesBase"
import { mergeDbAlternatives } from "../../list/business/helpers"

export async function fetchDirectSearchOrVehiclePartsArticles({
    queryKey: [, , request],
    pageParam,
    partToReplaceErpAlternatives,
}: QueryFunctionContext<QueryKey<GetArticlesRequest>, number> & { partToReplaceErpAlternatives?: Article[] }): Promise<ArticlePage> {
    if (!request) {
        return Promise.reject()
    }

    const articleResponse = await PartsRepository.getArticlesWithPageInfo({
        ...request,
        pageIndex: pageParam ?? 0,
    })

    const hasNextPage = articleResponse.articleListCount !== undefined && articleResponse.articleListCount >= articleResponse.pageSize

    const articles = partToReplaceErpAlternatives?.length
        ? mergeDbAlternatives([...partToReplaceErpAlternatives], articleResponse.articles, undefined, false)
        : articleResponse.articles

    return {
        data: articles,
        nextPageIndex: hasNextPage ? articleResponse.pageIndex + 1 : undefined,
    }
}
