import { Box, Button, DefaultDialog, Link, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    open: boolean
    url: string | undefined
    onClose(): void
}

export function ExternalBasketDialog({ open, url, onClose }: Props) {
    const { translate } = useLocalization()

    return (
        <DefaultDialog
            open={open}
            fullScreen
            fullWidth
            maxWidth={false}
            PaperProps={{
                sx: (theme) => ({
                    margin: theme.spacing(4),
                }),
            }}
        >
            <Box display="flex" flexWrap="wrap" alignItems="flex-start" sx={(theme) => ({ padding: theme.spacing(2) })}>
                <Typography flex="1" variant="body2">
                    {translate(13882)}
                    <br />
                    <Link href={url} target="_blank" rel="noopener">
                        {url}
                    </Link>
                </Typography>

                <Button color="highlight" onClick={onClose}>
                    {translate(13883)}
                </Button>
            </Box>
            <iframe style={{ flex: 1 }} title="external-basket" src={url} />
        </DefaultDialog>
    )
}
