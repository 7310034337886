import { useMemo } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { ArticleIdentifier, ConfigParams, LinkedItemsRole } from "@tm/models"
import { useDefaultErpSystem } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { useQuery } from "react-query"
import { getArticlesFromTraderArticleDictionary } from "../../../list/business/helpers"
import { PartToReplaceData } from "../PartsAlternativesModuleState"
import { getArticlesByArticleNoWithOptVehicle, getArticlesByWholesalerArticleNos } from "../../../../data/repositories"
import { useErpInfo } from "../../../../../../erp/src/micros/_helpers/useErpInfo"

export const useGetPartToReplaceErpAlternatives = (isEnabled: boolean, partToReplaceData?: PartToReplaceData) => {
    const workTask = useWorkTask()?.workTask
    const { erpSystemConfig } = useDefaultErpSystem()

    const isFeatureEnabled = Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp

    const shouldLoadData = isEnabled && isFeatureEnabled && !!partToReplaceData?.article

    const { erpInfo, loading } = useErpInfo(shouldLoadData ? partToReplaceData.article : undefined, "details", undefined, erpSystemConfig)

    const alternativeArticles = useMemo(
        () => (!loading ? erpInfo?.articles?.filter((a) => a.itemsRole === LinkedItemsRole.Alternative) : undefined),
        [erpInfo, loading]
    )

    const { data, isLoading, isFetched } = useQuery({
        queryKey: ["partsAlternatives_getPartToReplaceErpAlternatives", alternativeArticles],
        queryFn: async () => {
            let prom

            if (!alternativeArticles) {
                return undefined
            }

            if (alternativeArticles.some((a) => !!a.wholesalerArticleNumber)) {
                prom = getArticlesByWholesalerArticleNos(
                    alternativeArticles.map((x) => x.wholesalerArticleNumber),
                    workTask?.vehicle?.tecDocTypeId
                ).then((x) => getArticlesFromTraderArticleDictionary(x, undefined).articles)
            } else {
                const articleIdentifier: Array<ArticleIdentifier> = []

                alternativeArticles.forEach((a) => {
                    if (a.dataSupplierId && a.dataSupplierArticleNumber && partToReplaceData?.article?.productGroup?.id) {
                        articleIdentifier.push({
                            supplierId: a.dataSupplierId,
                            supplierArticleNo: a.dataSupplierArticleNumber,
                            productGroupId: partToReplaceData.article.productGroup.id,
                        })
                    }
                })

                prom = getArticlesByArticleNoWithOptVehicle(articleIdentifier)
            }

            const articles = await prom
            const partToReplaceQuantity = partToReplaceData?.article?.quantity
            return !partToReplaceQuantity
                ? articles
                : articles.map((x) => ({
                      ...x,
                      quantity: partToReplaceQuantity || x.quantity,
                  }))
        },
        enabled: !!alternativeArticles && shouldLoadData,
        staleTime: 4 * 60 * 60 * 1000, // 4 hours
    })

    const fetched = shouldLoadData ? !isLoading && isFetched : false
    const isLoaded = isFeatureEnabled ? fetched : true

    return { partToReplaceErpAlternatives: data ?? [], isLoading, isLoaded }
}
